import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Ariadna FAQs",
  "layout": "collabpage",
  "banner": "/images/core/ari_banner.jpg",
  "banner_caption": "ariadna",
  "regenerate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`What is Ariadna?`}</h4>
    <p>{`Ariadna is a mechanism created to facilitate the interaction between the Advanced Concepts Team research and the european academia.`}</p>
    <hr></hr>
    <h4>{`How does Ariadna work?`}</h4>
    <p>{`Periodical and ad-hoc announcements of opportunity are made targeted to specific research communities. These are the Calls for Proposals and Calls for Ideas. Interested research groups are
then be able to submit proposals via ESA's `}<a parentName="p" {...{
        "href": "http://emits.sso.esa.int/"
      }}>{`EMITS`}</a>{`.`}</p>
    <p>{`It is important to know that registration in EMITS is required, in order to submit the proposal.`}</p>
    <hr></hr>
    <h4>{`Why Ariadna?`}</h4>
    <p>{`ESA is interacting with the academic world through a number of mechanisms and schemes, including research and hardware development contracts, direct support (e.g. the International Space University) and research fellowships. However, the need for a closer link between ESA and the academic world and an easier way to cooperate on advanced research topics was felt on both sides.`}</p>
    <hr></hr>
    <h4>{`What is different about Ariadna?`}</h4>
    <p>{`With Ariadna, an effort has been made to adapt the working procedures to the constraints of the academic world. For instance, the preparation an submission of good, valid study proposals should not require much time, and all the effort should be spent on making it technically and scientifically sound. This process should never become a heavy burden for the proposers, normally confronted with demanding research or academic obligations.`}</p>
    <p>{`Ariadna also provides common goals for sets of activities giving their sequence consistency and continuity. This will be done by defining priorities i.e. lines of activity for advanced research, which will be periodically updated. The ACT will manage Ariadna and will become a permanent point of reference beyond the duration of a single contract.`}</p>
    <p>{`Finally, active collaboration - rather than merely supervision - will be sought. Publications of the results in international journals or presentations at international conferences will also be particularly encouraged.`}</p>
    <hr></hr>
    <h4>{`What are the Calls for Proposals and Call for Ideas?`}</h4>
    <p>{`Ariadna will encompass two types of announcements or calls:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The Calls for Proposals consist of announcements of specific assessment studies, in which the topic of research and tasks to be performed have been previously defined by ESA's Advanced Concepts Team and relate to the
team's basic research categories, and for which the need for a short, focused evaluation has been identified.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Calls for Ideas consist of announcments where research groups are invited to submit ideas for their own proposals relating to a general theme, specified by the ACT. In this case, and after a preliminary selection has taken place researchers will be expected to work in collaboration with the other participants in the Call for Ideas contributing to a comprehensive, multidisciplinary review of the proposed theme.`}</p>
      </li>
    </ul>
    <hr></hr>
    <h4>{`How are the subjects of the Calls decided?`}</h4>
    <p>{`The subjects of the calls for proposals are decided by internal ESA experts (normally ACT members in consultation with other ESA groups) partly based on the perceived interest in the academic community but also -and very importantly-on the assessment of their potential benefits for space programmes and the degree of innovation of the proposed work. We normally favour areas that having this potential are not yet well established and that are rather specific to space-related research.`}</p>
    <hr></hr>
    <h4>{`Who can participate in Ariadna?`}</h4>
    <p>{`Researchers participating in the Ariadna scheme should be either full-time research staff, post-doc research fellows or PhD students in a university or academic research group. Study teams must be made up by a minimum of two researchers, and basic contact details should be provided for both when signing in with Ariadna. One of them must be a junior researcher with up to five years experience of post-doctoral research and should have a track record in a research field relevant to the study. Both members should demonstrate evidence of scholarly publications relevant in some to the study. Other members may also be included in the study team, giving the possibility to Ph.D. students and post-graduates students to participate to Ariadna projects and to partnerships to be established between different research centers. These participants and their contribution to the study must be identified from the start.`}</p>
    <p>{`Calls are restricted to universities and research groups within universities. All other entities (e.g. SME's) are not allowed to submit
proposals, but can participate on a zero-cost basis.`}</p>
    <hr></hr>
    <h4>{`How long will the studies be and what will be the level of funding?`}</h4>
    <p>{`Administrative procedures are made as simple and transparent as possible, also by considering a simplified, `}{`"`}{`predictable`}{`"`}{` type of contract. Three types of studies have been defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Short: study budget not exceeding 15 k`}{`€`}{`. Additional travel allowance (if applicable) covering 2 person travel expenses to the one meeting at ESTEC. No mid-term review is foreseen.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Standard: study budget not exceeding 25 k`}{`€`}{`. Additional travel allowance (if applicable) covering 2 person travel expenses for two meetings at ESTEC. Progress Meeting, if it is required, will be at contractor premises and thus travel expenses will not be applicable.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Extended: maximum study budget of 35 k`}{`€`}{`. Additional travel allowance if applicable as in Standard studies.`}</p>
      </li>
    </ul>
    <p>{`These three simple modalities will enable easy replication of the procedures and the elaboration of standard study documentation templates, in the few cases where they will be needed (i.e. contact details of team members, travel allowance request). Other `}{`"`}{`traditional`}{`"`}{` sections that are mandatory in larger activities will not be required (work package descriptions, cost forms, etc)`}</p>
    <hr></hr>
    <h4>{`What should the Proposals be like?`}</h4>
    <p>{`In order to make it easy and quick to elaborate a good Ariadna proposal, these will be required to contain the following sections:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Abstract of study proposal`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Technical and scientific contents`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Key personnal description (CVs, list of publications)`}</p>
      </li>
    </ul>
    <p>{`Further details on the required content of proposals will be published together with the Calls.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      